import React, { useContext } from "react";
import { userContext } from "../context/userContext";

const Balance = ({ coin, open }) => {
  const { data, setData } = useContext(userContext);
  const formatCoin = coin.toLocaleString();
  return (
    <div
      className={`flex flex-col items-center gap-1 ${
        open ? "blur-[2px]" : "blur-none"
      }`}
    >
      <h1 className="text-[20px] audiowide-regular uppercase mt-[25px]">
        DHN Community
      </h1>
      <div className="flex items-center ">
        <img className="w-[42px] h-[42px] mb-2" src="/img/coin.png" alt="" />
        <p className="text-[40px] ">{formatCoin}</p>
      </div>
      <p className="font-[700] text-[13px]">Total Balance</p>
    </div>
  );
};

export default Balance;
