import React, { useContext } from "react";
import Balance from "../components/Balance";
import Navbar from "../components/Navbar";
import { userContext } from "../context/userContext";
import BellIcon from "../icons/BellIcon";
import MoreIcon from "../icons/MoreIcon";
import ProfileIcon from "../icons/ProfileIcon";
import Settingicon from "../icons/Settingicon";

const MinePage = ({ coin,id }) => {
  const { data, setData } = useContext(userContext);
  return (
    <div className="flex flex-col items-center w-full mt-[30px] px-[30px]">
      <div className={`flex justify-between items-center w-full `}>
        <div className="flex justify-center items-center gap-1">
          <ProfileIcon />
          <p className="text-[12px] font-[600]">{data?.user?.username}</p>
        </div>
        <div className="flex items-center">
         
          
        </div>
      </div>
      <h1 className="text-[20px] audiowide-regular uppercase mt-[25px]">
        DHN Community
      </h1>
      {/* <Balance coin={coin} /> */}
      <div className="bg-[#004382] rounded-t-[50px] border-t-[4px] h-[68%] border-[#FFFFFF] absolute bottom-0 left-0 right-0">
        <span className="absolute top-[-60px]">
          <img src="/img/mineCoins.png" alt="" />
          <h3 className="font-[700] text-[36px] text-center ">COMING SOON</h3>
        </span>
        <Navbar />
      </div>
    </div>
  );
};

export default MinePage;
