import React, { useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";

const Button = ({ timeout, energy, coin, setCoin, setEnergy, level }) => {
  const ref = useRef();
  const [touchPositions, setTouchPositions] = useState([]);
  async function hadnleClick(e) {
    if (energy <= 0) {
      setTimeout(() => {
        setTimeout(timeout);
      }, 1000);
      return toast.error("No Energy");
    }
    clearTimeout(timeout);
    e.preventDefault();
    const energys = energy - e.touches.length;
    setEnergy(energys);

    // const element = document.createElement("span");
    // element.innerText = `${level}`;
    const newPositions = Array.from(e.touches).map((touch) => ({
      x: touch.clientX,
      y: touch.clientY,
    }));
    setTouchPositions((prevPositions) => [...prevPositions, ...newPositions]);
    // element.classList = `animation_fade absolute text-white`;
    // element.style.left = clientX + "px";
    // element.style.top = clientY + "px";
    // ref.current.append(element);
    // const img = document.createElement("img");
    // img.src = "/img/coin.png";
    // img.classList = `animation_fade absolute w-[40px]`;
    // img.style.left = clientX + (level == 10 ? 30 : 10) + "px";
    // img.style.top = clientY + "px";
    // ref.current.append(img);
    const touchCount = e.touches.length * level;

    // به ازای هر انگشت یک سکه اضافه کنید
    setCoin((prevCoins) => prevCoins + touchCount);

    setTimeout(() => {
      setTimeout(timeout);
    }, 1000);
  }
  return (
    <div ref={ref} className="mt-[50px]  ">
      <img
        style={{ userSelect: "none" }}
        onTouchStart={hadnleClick}
        className="w-[245px] h-[244px] hover:scale-[.97] duration-75"
        src="/img/button.png"
        alt=""
      />
      {touchPositions.map((pos, index) => (
        <span
          key={index}
          className="animation_fade text-white flex justify-center items-center"
          style={{
            position: "absolute",
            left: pos.x,
            top: pos.y,
            transform: "translate(-50%, -50%)",
            fontSize: "20px",
          }}
        >
          <img
            key={index}
            style={{}}
            className=" w-[40px] pb-2"
            src="/img/coin.png"
            alt=""
          />
          {level}
        </span>
      ))}
    </div>
  );
};

export default Button;
