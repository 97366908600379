import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Balance from "../components/Balance";
import Navbar from "../components/Navbar";
import { userContext } from "../context/userContext";
import BellIcon from "../icons/BellIcon";
import FlagIcon from "../icons/FlagIcon";
import FriendsIcon from "../icons/FriendsIcon";
import MoreIcon from "../icons/MoreIcon";
import ProfileIcon from "../icons/ProfileIcon";
import Settingicon from "../icons/Settingicon";
import TelegramIcon from "../icons/TelegramIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import { FaXTwitter } from "react-icons/fa6";
import { httpReauest } from "../utils/httpRequest";
import { useState } from "react";
const QuestPage = ({ coin, setCoin, setEnergy, id, energy, setId }) => {
  const { data, setData } = useContext(userContext);
  const DailyReward = data?.tasks;
  console.log(DailyReward);
  console.log(data);
  async function handleYoutube(e) {
    if (data?.user?.youtube) {
      return e.preventDefault();
    } else {
      const postId = await httpReauest(
        "POST",
        "/user/youtube",
        { telegramId: id },
        {}
      ).then(async (e) => {
        if (e.status === 201) {
          const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
            (e) => {
              if (e.status === 200) {
                setCoin(e.data.data.user.balance);
                setData(e.data.data);
                setEnergy(e.data.data.user.energy);
                setId(id);
              }
            }
          );
        }
      });
    }
  }
  async function handleTlegram(e) {
    if (data?.user?.telegram) {
      return e.preventDefault();
    }
    const postId = await httpReauest(
      "POST",
      "/user/telegram",
      { telegramId: id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setCoin(e.data.data.user.balance);
              setData(e.data.data);
              setEnergy(e.data.data.user.energy);
              setId(id);
            }
          }
        );
      }
    });
  }
  async function handleTwiter(e) {
    if (data?.user?.twiter) {
      return e.preventDefault();
    }
    const postId = await httpReauest(
      "POST",
      "/user/twiter",
      { telegramId: id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setCoin(e.data.data.user.balance);
              setData(e.data.data);
              setEnergy(e.data.data.user.energy);
              setId(id);
            }
          }
        );
      }
    });
  }
  async function handleDaily(i,e) {
    if (e?.done) {
      return i.preventDefault();
    }

    const postId = await httpReauest(
      "POST",
      "/task",
      { userId: data?.user?._id, taskId: e?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setCoin(e.data.data.user.balance);
              setData(e.data.data);
              setEnergy(e.data.data.user.energy);
              setId(id);
            }
          }
        );
      }
    });
  }
  return (
    <div className="flex flex-col items-center w-full mt-[30px] px-[30px]">
      <div className={`flex justify-between items-center w-full `}>
        <div className="flex justify-center items-center gap-1">
          <ProfileIcon />
          <p className="text-[12px] font-[600]">{data?.user?.username}</p>
        </div>
        <div className="flex items-center">
          
          
        </div>
      </div>
      <Balance coin={coin} />
      <div className="bg-[#004382]  rounded-t-[50px] border-t-[4px] h-[68%] border-[#FFFFFF] absolute bottom-0 left-0 right-0">
        <div className="m-4 mx-8 h-[400px] overflow-y-auto pb-[60px] ">
          <div className="flex justify-between ">
            <h3 className="text-[24px] text-[#F4B92E] font-[700]">
              Earn more coins
            </h3>
            
          </div>
          {/* <h4 className="text-[16px] font-[500] mt-3">DHN Youtube</h4> */}
          
          <h4 className="text-[16px] font-[700] mt-3">Daily tasks</h4>
          {DailyReward?.map((e) => (
            <Link
              onClick={(i) => handleDaily(i, e)}
              to={e.url}
              target={"_blank"}
              className={`w-full  grid grid-cols-8 justify-items-start items-center  rounded-[8px] py-[14px] mt-2 gap-3 px-4 ${
                e?.done ? "bg-green-500" : "bg-[#002F52]"
              }`}
            >
              <FlagIcon />
              <p className="text-[16px] font-[700] col-span-5">{e.content}</p>
              <span className="flex items-center col-span-2">
                <p className=" text-[16px] font-[700] ml-3">{e.point}</p>
                <img className="w-5 mb-1" src="/img/coin.png" alt="" />
              </span>
            </Link>
          ))}

          <h4 className="text-[16px] font-[500] mt-3">Tasks list</h4>
          <div
            className={`w-full   rounded-[8px] py-3 px-2 ${
              data?.user?.youtube ? "bg-green-500" : "bg-[#FFFFFF]"
            }`}
          >
            <Link
              onClick={handleYoutube}
              to={"https://www.youtube.com/@DHNCommunity"}
              target={"_blank"}
              className="w-full h-full flex justify-between items-center"
            >
              <span className="flex items-center">
                <YoutubeIcon />
                <p className="text-black text-[16px] font-[700] ml-3">
                  Subscribe our channal
                </p>
              </span>
              <span className="flex items-center">
                <p className="text-black text-[16px] font-[700] ml-3">10000</p>
                <img className="w-5 mb-1" src="/img/coin.png" alt="" />
              </span>
            </Link>
          </div>
          <Link
            to={"https://t.me/DHN_community"}
            onClick={handleTlegram}
            target={"_blank"}
            className={`w-full  grid grid-cols-8 justify-items-start items-center rounded-[8px] py-[14px] mt-1  px-4 gap-2 ${
              data?.user?.telegram ? "bg-green-500" : "bg-[#002F52]"
            }`}
          >
            <TelegramIcon />
            <p className="text-[16px] col-span-5 font-[700] ">
              Join our TG channel
            </p>
            <span className="flex items-center col-span-2">
              <p className=" text-[16px] font-[700] ml-3">10000</p>
              <img className="w-5 mb-1" src="/img/coin.png" alt="" />
            </span>
          </Link>
          <Link
            to={"https://twitter.com/dhncommunity"}
            target={"_blank"}
            onClick={handleTwiter}
            className={`w-full  grid grid-cols-8 justify-items-start items-center rounded-[8px] py-[14px] mt-1  px-4 gap-2 ${
              data?.user?.twiter ? "bg-green-500" : "bg-[#002F52]"
            }`}
          >
            <FaXTwitter />
            <p className="text-[16px] col-span-5 font-[700] ">
              Follow our X account
            </p>
            <span className="flex items-center col-span-2">
              <p className=" text-[16px] font-[700] ml-3">10000</p>
              <img className="w-5 mb-1" src="/img/coin.png" alt="" />
            </span>
          </Link>
          <Link to={'/freinds'} className={`w-full  grid grid-cols-8 justify-items-start items-center rounded-[8px] py-[14px] mt-1  px-4 gap-2 ${data?.invitedPepole.length >= 10 ? 'bg-green-500': "bg-[#002F52]"  }`}>
            <FriendsIcon />
            <p className="text-[16px] col-span-7 font-[700] ">
              Invite 10 friends
            </p>
          </Link>
        </div>
        <Navbar />
      </div>
    </div>
  );
};

export default QuestPage;
