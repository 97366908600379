import React, { useContext } from "react";
import { userContext } from "../context/userContext";
import KeyIcon from "../icons/KeyIcon";
import SupriseIcon from "../icons/SupriseIcon";

const Shelfs = () => {
  const { data, setData } = useContext(userContext);
  return (
    <div className="grid w-full grid-cols-2 text-[11px] mt-[20px] gap-2">
      
      <div className="bg-[#32363C] rounded-[10px] px-[8px] py-[8px] flex flex-col items-center">
        <span className="flex justify-center items-center gap-1 w-full">
          <p className="text-[#6F72E2]">Current Level</p>
        </span>
        <span className="px-3">{data?.user?.level}</span>
      </div>
      <div className="bg-[#32363C] rounded-[10px] px-[8px] py-[8px] flex flex-col items-center">
        <span className="flex justify-center items-center gap-1 w-full">
          <p className="text-[#84CB69]">DHN GameFi</p>
        </span>
        <span className=" flex justify-center items-center">
          <img className="w-[15px] h-[15px] mb-1" src="/img/coin.png" alt="" />
          <p className="mr-1">Soon</p>
          <SupriseIcon />
        </span>
      </div>
    </div>
  );
};

export default Shelfs;
