import React, { useContext } from "react";
import { userContext } from "../context/userContext";
import ExcludeIcon from "../icons/ExcludeIcon";
import { httpReauest } from "../utils/httpRequest";

const BoostLevelUp = ({ id, setEnergy, setCoin, setOpenBoost, setId }) => {
  const { data, setData } = useContext(userContext);
  const activeLevel = data?.user?.level;
  function levelCost(day) {
    const baseAmount = 1000; // مقدار پایه
    let total = baseAmount; // شروع با مقدار پایه

    for (let i = 1; i < day; i++) {
      total += baseAmount + i * 3000; // افزایش تدریجی
    }

    return total;
  }
  async function handleLevelup(e) {
    if (data?.user?.balance >= levelCost(activeLevel)) {
      const response = await httpReauest(
        "POST",
        "/user/levelup",
        { telegramId: id },
        {}
      ).then(async (e) => {
        if (e.status === 201) {
          const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
            (e) => {
              if (e.status === 200) {
                setData(e.data.data);
                setCoin(e.data.data.user.balance);
                setEnergy(e.data.data.user.energy);
                setId(id);
                setOpenBoost(false)
              }
            }
          );
        }
      });
    }
  }

  return (
    <div className="flex flex-col items-center w-full mt-4">
      {activeLevel >= 10 ? (
        <>
          <button onClick={()=>setOpenBoost(false)} className=" absolute top-5 right-6 ">
            <ExcludeIcon />
          </button>
          <img className="w-10" src="/img/tap.png" alt="" />
          <h3 className="text-[25px] font-[500] mt-2">Multie Tap</h3>
          <p className="mt-5 text-[16px] font-[700]">Your level is MAX</p>
          
          <button className="bg-[#004382] w-[90%] h-[70px] rounded-[15px] items-center flex justify-center mx-4 mt-4 mb-6 text-[16px] font-[600] text-center">
            Back to Home
          </button>
        </>
      ) : (
        <>
          <button onClick={()=>setOpenBoost(false)} className=" absolute top-5 right-6 ">
            <ExcludeIcon />
          </button>
          <img className="w-10" src="/img/tap.png" alt="" />
          <h3 className="text-[25px] font-[500] mt-2">Multie Tap</h3>
          <p className="mt-3 text-[16px] font-[700]">
            {levelCost(activeLevel)} coins to go to level {activeLevel + 1}{" "}
          </p>
          <span className="flex items-center justify-start">
            <img className="w-[40px] mb-2" src="/img/coin.png" alt="" />
            <p>{levelCost(activeLevel)}</p>
          </span>
          <button
            onClick={handleLevelup}
            className="bg-[#004382] w-[90%] h-[70px] rounded-[15px] items-center flex justify-center mx-4 mt-4 mb-6 text-[16px] font-[600] text-center"
          >
            {data?.user?.balance >= levelCost(activeLevel) ? 'Level Up' : 'Not enough Coins'}
          </button>
        </>
      )}
    </div>
  );
};

export default BoostLevelUp;
