import React, { useContext, useRef } from "react";
import { useState } from "react";
import Balance from "../components/Balance";
import Button from "../components/Button";
import Shelfs from "../components/Shelfs";
import BellIcon from "../icons/BellIcon";
import DailyReward from "../components/DailyReward";
import MoreIcon from "../icons/MoreIcon";
import ProfileIcon from "../icons/ProfileIcon";
import Settingicon from "../icons/Settingicon";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import { httpReauest } from "../utils/httpRequest";
import { userContext } from "../context/userContext";
import Loading from "../components/Loading";
import BoostLevelUp from "../components/BoostLevelUp";

const Home = ({
  energy,
  setEnergy,
  coin,
  setCoin,
  sendData,
  id,
  setId,
  loading,
  setLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { data, setData } = useContext(userContext);
  const [openBoost, setOpenBoost] = useState(false);
  const timeout = setTimeout(() => {
    if (energy >= 6500) {
      return;
    } else if (data?.user) {
      setEnergy(energy + 1);
    }
  }, 1000);

  async function getUser() {
    if (id === "") {
      const user = searchParams.get("telegramId");
      const response = await httpReauest("GET", `/user/${user}`, {}, {});
      setData(response.data.data);
      setCoin(response.data?.data?.user?.balance);
      setEnergy(response?.data?.data?.user?.energy);
      setId(user);
      setLoading(false);
      if (response?.data?.data?.user?.canClaim === true) {
        setOpen(true);
      }
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    return () => {
      sendData();
      // کدهایی که می‌خواهید هنگام انمونت شدن کامپوننت اجرا شود
    };
  }, [coin]);

  const level = data?.user?.level;

  return (
    <div
      className={`flex flex-col items-center w-full mt-[20px] px-[30px] overflow-hidden `}
    >
      {loading ? (
        <Loading holloween={true} />
      ) : (
        <>
          <div
            className={`flex justify-between items-center w-full ${
              open ? "blur-[2px]" : "blur-none"
            }`}
          >
            <div className="flex justify-center items-center gap-1">
              <ProfileIcon />
              <p className="text-[12px] font-[600]">{data?.user?.username}</p>
            </div>
            <div className="flex items-center">
              
            </div>
          </div>
          <Balance coin={coin} open={open} />
          <Shelfs />
          <Button
            level={level}
            timeout={timeout}
            energy={energy}
            setEnergy={setEnergy}
            coin={coin}
            setCoin={setCoin}
          />

          <span
            className={`${
              open ? "bottom-[-1%]" : "bottom-[-180%]"
            } absolute bg-[#001930] rounded-t-[50px] left-0 right-0 border-t-[4px] overflow-hidden border-t-[#FFFFFF] w-full  z-10 duration-300`}
          >
            <DailyReward
              id={id}
              setId={setId}
              setEnergy={setEnergy}
              setCoin={setCoin}
              setOpen={setOpen}
            />
          </span>
          <span
            className={`${
              openBoost ? "bottom-[-1%]" : "bottom-[-180%]"
            } absolute bg-[#001930] rounded-t-[50px] left-0 right-0 border-t-[4px] overflow-hidden border-t-[#FFFFFF] w-full  z-10 duration-300`}
          >
            <BoostLevelUp
              setOpenBoost={setOpenBoost}
              id={id}
              setId={setId}
              setEnergy={setEnergy}
              setCoin={setCoin}
            />
          </span>
          <Navbar energy={energy} setOpenBoost={setOpenBoost}/>
        </>
      )}
    </div>
  );
};

export default Home;
