import React from "react";
import HomeIcon from "../icons/HomeIcon";
import EnergyIcon from "../icons/EnergyIcon";
import MineIcon from "../icons/MineIcon";
import FriendsIcon from "../icons/FriendsIcon";
import QuestIcon from "../icons/QuestIcon";
import WalletIcon from "../icons/WalletIcon";
import { Link, useLocation } from "react-router-dom";
const Navbar = ({ energy,setOpenBoost }) => {
  const location = useLocation();
  return (
    <div className="flex flex-col w-full items-center absolute bottom-4 justify-center px-[30px] ">
      {energy && (
        <div className="flex justify-between items-center w-full text-[14px] font-[600]">
          <span className="flex items-center justify-start gap-2">
            <EnergyIcon />
            <p>{energy} / 6500</p>
          </span>
          <span onClick={()=>setOpenBoost(true)}>Boost</span>
        </div>
      )}
      <div className="grid grid-cols-4 w-full bg-[#2C2C2C] text-[#8C8E91] py-2 px-3 mt-2 rounded-[15px]">
        <Link
          to={"/"}
          className={`flex flex-col justify-center items-center text-[9px] font-[500] duration-150  rounded py-1 ${location.pathname === '/' ? 'bg-[#222222] ': "bg-none text-[#565656]" }`}
        >
          <HomeIcon location={location} />
          <p className="">Home</p>
        </Link>
        <Link
          to={"/mine"}
          className={`flex flex-col justify-center items-center text-[9px] font-[500] duration-150  rounded py-1 ${location.pathname === '/mine' ? 'bg-[#222222]': "bg-none text-[#565656]" }`}
        >
          <MineIcon location={location}/>
          <p>Mine</p>
        </Link>
        {/* <Link
          to={"/freinds"}
          className={`flex flex-col justify-center items-center text-[9px] font-[500] duration-150  rounded py-1 ${location.pathname === '/freinds' ? 'bg-[#222222]': "bg-none text-[#565656]" }`}
        >
          <FriendsIcon location={location}/>
          <p>Friends</p>
        </Link> */}
        <Link
          to={"/quest"}
          className={`flex flex-col justify-center items-center text-[9px] font-[500] duration-150  rounded py-1 ${location.pathname === '/quest' ? 'bg-[#222222]': "bg-none text-[#565656]" }`}
        >
          <QuestIcon location={location}/>
          <p>Quests</p>
        </Link>
        <Link
          to={"/wallet"}
          className={`flex flex-col justify-center items-center text-[9px] font-[500] duration-150  rounded py-1 ${location.pathname === '/wallet' ? 'bg-[#222222]': "bg-none text-[#565656]" }`}
        >
          <WalletIcon location={location}/>
          <p>Wallet</p>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
